import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { first, get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import * as Accessa from 'accessa'
import { Box } from '@material-ui/core'
import DarkMode from '../components/DarkMode'
import { getLocale } from '../utils/i18n-helpers'

export const getFilePaths = (nodes = [], nodeLocale) =>
  nodes.reduce((acc, x) => {
    const pdf = get(x, 'addYourPdFsHere')
    const file = Array.isArray(pdf) ? first(pdf) : null
    const path = get(file, 'file.url')

    if (
      !file ||
      !x.node_locale.startsWith(nodeLocale) ||
      !path
    )
      return acc

    return acc.concat({
      title: get(x, 'title'),
      size: get(file, 'file.details.size'),
      contentType: get(file, 'file.contentType'),
      path,
    })
  }, [])

const Download = () => {
  const {
    allContentfulDownloads: { nodes },
  } = useStaticQuery(graphql`
    query {
      allContentfulDownloads(
        sort: { fields: title, order: ASC }
      ) {
        nodes {
          title
          addYourPdFsHere {
            file {
              url
              details {
                size
              }
              contentType
            }
          }
          node_locale
        }
      }
    }
  `)

  const { t } = useTranslation('titles')
  const resources = getFilePaths(nodes, getLocale())

  return (
    <DarkMode>
      <Box
        bgcolor="background.default"
        color="primary.contrastText"
      >
        <Accessa.Headers.Simple
          title={t('downloads')}
          renderTop={<Box pt={28} />}
        />
        <Container>
          <Box pt={10} pb={10}>
            <Accessa.Lists.Downloads
              lists={resources}
              gridItem={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4,
              }}
            />
          </Box>
        </Container>
      </Box>
    </DarkMode>
  )
}

Download.propTypes = {}

export default Download
