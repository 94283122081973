import { makeTemplateWithGlobal } from '../../components/withGlobals'
import Download from '../../templates/download'

export const DownloadTemplate = makeTemplateWithGlobal(
  Download
)

export default DownloadTemplate(
  [
    {
      node_locale: 'fr-CA',
      relativePath: '/fr-ca/telechargements',
    },
  ],
  {
    nodeLocale: 'en-CA',
    seo: {
      title: 'Downloads',
      description: {
        description:
          'CRG strives to provide useful content on our materials, products, and applications',
      },
    },
  }
)
